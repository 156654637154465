import React, {Fragment, useState} from 'react';
import PageHeader from "@/components/PageHeader";
import {useTranslation} from "react-i18next";
import SearchInput from "@/UI/SearchInput";
import s from './ArchivePage.module.scss';
import {ITableDataHeader} from "@/components/Table/Table";
import Table from "@/components/Table";
import TableRow from "@/components/Table/components/TableRow";
import TableColumn from "@/components/Table/components/TableColumn/TableColumn";
import Pagination from "@/UI/Pagination";
import Avatar from "@/UI/Avatar";
import Tag from "@/UI/Tag";
import useFetchTasks from "@hooks/query/tasks/useFetchTasks";
import useMutationTaskUpdate from "@hooks/mutations/tasks/useMutationTaskUpdate";
import {DateTime} from "luxon";
import {getDeadlineStyle} from "@/utils/deadlineNotificator";

const pageSize = 10;

export default function ArchivePage() {
    const {t} = useTranslation();
    const [value, setValue] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const {data: tasks, refetch} = useFetchTasks({page, limit: pageSize}, `filter.is_archive=$in:true`);

    const updateTask = useMutationTaskUpdate();

    const handleToKanban = async (id: number) => {
        await updateTask.mutateAsync({
            id: id,
            body: {
                is_archive: false,
            }
        });
        refetch();
    };

    const dateMask = (date: string | undefined | null) => {
        if (date) {
            return DateTime.fromISO(date).toFormat('MMMM dd, yyyy \'at\' HH:mm');
        }
    };

    const headerData: ITableDataHeader[] = [
        {title: `${t('archive.name')}`},
        {title: `${t('archive.creator')}`},
        {title: `${t('archive.responsible')}`},
        {title: `${t('archive.startDate')}`},
        {title: `${t('archive.deadline')}`},
        {title: `${t('archive.tags')}`, position: 'flex-end'},
        {title: '', position: 'flex-end'},
    ];

    const headerGrid = [
        'minmax(140px, 1fr)',
        'minmax(140px, 1fr)',
        'minmax(140px, 200px)',
        'minmax(140px, 200px)',
        'minmax(140px, 250px)',
        'minmax(140px, 1fr)',
        'minmax(140px, 140px)',
    ];

    const totalPages = tasks ? tasks.meta.totalPages : 1;


    return (
        <div className={s.container}>
            <PageHeader title={t('popupSelector.archive.title')}>
                <SearchInput value={value} onChange={(e) => setValue(e.target.value)}/>
            </PageHeader>
            <div className={s.container__content}>
                <Table dataHeaders={headerData} gridWidthSize={headerGrid}>
                    {tasks && tasks.data.map((item, rowIndex) => (
                        <Fragment key={rowIndex}>
                            <TableRow gridWidthSize={headerGrid}>
                                <TableColumn>{item.title}</TableColumn>
                                <TableColumn>
                                    <div className={s.container__content_avatar}>
                                        <Avatar name={item.creator.name} picture={item.creator.picture}/>
                                    </div>
                                    {item.creator.name}
                                </TableColumn>
                                <TableColumn>
                                    <div className={s.container__content__avatarGallery}>
                                        {item.responsible.map((user, i) => (
                                            <div key={i} className={s.container__content__avatarGallery__img}>
                                                <Avatar name={user.name} picture={user.picture}/>
                                            </div>
                                        ))}
                                    </div>
                                </TableColumn>
                                <TableColumn>{dateMask(item.start_date)}</TableColumn>
                                <TableColumn>
                                    <span className={getDeadlineStyle(item.deadline)}>{dateMask(item.deadline)}</span>
                                </TableColumn>
                                <TableColumn position="flex-end">
                                    {item.tags && item.tags.length > 3 ? (
                                        <>
                                            {item.tags.slice(0, 3).map((elem, index) => (
                                                <Tag key={index}>{elem}</Tag>
                                            ))}
                                            <Tag>+{item.tags.length - 3}</Tag>
                                        </>
                                    ) : (
                                        item.tags && item.tags.map((elem, index) => (
                                            <Tag key={index}>{elem}</Tag>
                                        ))
                                    )}
                                </TableColumn>
                                <TableColumn position="flex-end">
                                    <button className={s.container__content__button}
                                            onClick={() => handleToKanban(item.id)}>{t('archive.return')}
                                    </button>
                                </TableColumn>
                            </TableRow>
                        </Fragment>
                    ))}
                </Table>
            </div>
            {totalPages > 1 &&
                <Pagination allPageNumber={totalPages} currentPage={page} onPageChange={setPage}/>}
        </div>
    );
}
